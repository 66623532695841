import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import CreditCardDisplay from '../../../../components/credit-card/logo-and-last-four';
import ExpirationDisplay from '../../../../components/credit-card/expiration';
import RemoveButton from '../../../../components/buttons/remove-button';

const useStyles = makeStyles((theme) => ({
  empty: {
    marginTop: '10px'
  },
  table: {
    maxWidth: '800px',
    marginBottom: '15px'
  },
  title: {
    marginTop: '15px',
    fontWeight: 'bold',
    fontSize: '.9em'
  },
  cell: {
    borderBottom: '0px'
  },
  label: {
    fontWeight: 'bold'
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center'
  }
}));

const RemovePaymentMethod = (props) => {
  const classes = useStyles();
  const { subscriptionPaymentMethods, removePaymentMethod, values } = props;

  const renderRemoveButton = (index, valuesProp) => (
      <RemoveButton
        label="Remove"
        id={`paymentMethod-${index}`}
        onClick={(event) => {
          removePaymentMethod(event, valuesProp);
        }}
      />
  );

  if (!subscriptionPaymentMethods) {
    return null;
  }

  if (subscriptionPaymentMethods.length === 0) {
    return (
      <Fragment>
        <Typography variant="subtitle2" className={classes.title}>
          Subscription Payment Methods
        </Typography>
        <p className={classes.empty}>No credit cards added to subscription</p>
      </Fragment>
    );
  }
  const rows = subscriptionPaymentMethods
    ? subscriptionPaymentMethods.map((item, index) => {
      const { last, brand, expiration } = item;
      return {
        last,
        displayCard: <CreditCardDisplay last={last} brand={brand} />,
        displayExpiration: <ExpirationDisplay expiration={expiration} />,
        displayRemoveButton:
            subscriptionPaymentMethods.length > 1
              ? renderRemoveButton(index, values)
              : null
      };
    })
    : [];
  return (
    <div className="view-payment-method">
      <div className={classes.buttonContainer}></div>
      <Typography variant="subtitle2" className={classes.title}>
        Subscription Payment Methods
      </Typography>
      <Table className={classes.table}>
        <TableBody>
          {rows.map((row, index) => {
            const {
              last,
              displayCard,
              displayExpiration,
              displayRemoveButton
            } = row;

            return (
              <TableRow key={`${last}-${index}`}>
                <TableCell
                  className={`${classes.cell} ${classes.cardContainer}`}
                  align="left"
                  size="small"
                >
                  {displayCard}
                </TableCell>
                <TableCell className={classes.cell} align="left" size="small">
                  {displayExpiration}
                </TableCell>
                <TableCell className={classes.cell} align="left" size="small">
                  {displayRemoveButton}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export default RemovePaymentMethod;

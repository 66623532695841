import { gql } from '@apollo/client';

export default gql`
mutation refundInvoiceMutation ($invoiceId: String, $refundAmount: Float) {
  refundInvoice (invoiceId: $invoiceId, refundAmount: $refundAmount) {
      id
      status
      balance
      total
      refundAmount
    }
  }
`;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import CreditCardDisplay from '../../../../components/credit-card/logo-and-last-four';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 'bold'
  }
}));

const renderPaymentMethods = (paymentMethods, paymentMethodIds) => {
  // Originally, Subscriptions could have more than 1 payment method
  // this is no longer the case, and they need to be filtered by the payment methods
  // listed in the subscription item in the db.  This filter accomplishes that.
  const _paymentMethods = paymentMethods.filter((method) => paymentMethodIds.includes(method.id));
  if (_paymentMethods.length > 0) {
    return (
      <CreditCardDisplay
        last={_paymentMethods[0].last}
        brand={_paymentMethods[0].brand}
        key={_paymentMethods[0].token}
      />
    );
  }

  return null;
};

const SubscriptionPaymentMethods = (props) => {
  const classes = useStyles();
  const { paymentMethods, paymentMethodIds } = props;
  const renderedPaymentMethods = renderPaymentMethods(paymentMethods, paymentMethodIds);
  return (
    <Grid container>
      <Grid item xs={6}>
        <Typography variant="subtitle2" className={classes.title}>
          Payment Method
        </Typography>
      </Grid>
      <Grid item xs={6}>
        {renderedPaymentMethods}
      </Grid>
    </Grid>
  );
};

export default SubscriptionPaymentMethods;

import _ from 'lodash';
import dayjs from 'dayjs';
import React, { useState, useEffect, Fragment } from 'react';
import { useQuery } from '@apollo/client';
import Typography from '@material-ui/core/Typography';
import MUIDataTable from 'mui-datatables';
import getTransactions from '../queries/getTransactions';
import isAuthenticated from '../../../components/auth/isAuthenticated';
import CircleProgress from '../../../components/progress/circle';
import LogoAndLastFourDisplay from '../../../components/credit-card/logo-and-last-four';

const dateFormat = 'MM/DD/YYYY HH:mm:ss';

const getTimestampDisplay = (timestamp) => {
  if (timestamp) return dayjs(timestamp).format(dateFormat);

  return timestamp;
};

const getCurrencySymbol = (priceConfig, currency) => {
  const currencyObject = _.find(
    _.get(priceConfig, 'getPriceConfig.currencies'),
    { name: _.toUpper(currency) }
  );

  return _.get(currencyObject, 'symbol', '$');
};

const getAmountDisplay = (amount, currency, priceConfig) => [getCurrencySymbol(priceConfig), parseFloat(amount).toFixed(2)].join(' ');

// TODO: Currently the only two options are Chase Batch or Chase Online,
// this will need to be updated once we have more gateways.
const getGatewayDisplay = (gateway) => {
  if (gateway === 'Payrix') return 'Payrix';
  if (gateway === 'sdhwthf3-245457-0983-1092-0381-2093') return 'Payrix';
  if (gateway === '986d5562-e605-4e65-87d5-5a6df40c283b') return 'Chase Batch';

  return 'Chase Online';
};

const getStatusDisplay = (status, action = '') => {
  const displayStatuses = {
    APPROVED: 'Approved',
    'batch-waiting': 'Batched',
    batched: 'Pending',
    'vault: technical failure [unknown]': ''
  };

  if (action === 'refund') {
    _.set(displayStatuses, 'vault: technical failure [unknown]', 'Failed');
  }

  return _.get(displayStatuses, status, status);
};

const options = {
  elevation: 0,
  selectableRows: 'none',
  download: false,
  print: false,
  viewColumns: false,
  search: false,
  filter: false
};

const columns = [
  {
    name: 'Date',
    options: {
      filter: false,
      sort: true,
      sortOrder: { direction: 'desc' }
    }
  },
  {
    name: 'Payment Method',
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: 'Transaction ID',
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: 'Action',
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: 'Amount',
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: 'Gateway',
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: 'Response Code',
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: 'Auth Code',
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: 'Status',
    options: {
      filter: false,
      sort: true
    }
  }
];

const TransactionTable = (props) => {
  const { invoiceNumber, invoiceId, priceConfig } = props;
  const [rows, setRows] = useState([]);

  const { loading, data } = useQuery(getTransactions, {
    client: props.client,
    variables: {
      input: {
        externalId: invoiceId
      }
    }
  });

  const transactions = _.get(data, 'getTransactionsByExternalId');

  useEffect(() => {
    if (!transactions || _.size(transactions) === 0) return undefined;

    const loadTransactionRows = (transactionsArray) => {
      _.get(
        console,
        'info'
      )({
        method: 'loadTransactionRows',
        arguments: { transactions: transactionsArray }
      });

      const loadTransactionRow = (transaction) => {
        _.get(
          console,
          'info'
        )({ method: 'loadTransactionRow', arguments: { transaction } });

        try {
          return [
            // Formatted Transaction "time"
            getTimestampDisplay(_.get(transaction, 'time')),
            // Formatted Payment Method (Component)
            <LogoAndLastFourDisplay
              transaction={transaction}
              key={_.get(transaction, 'id')}
            />,
            // Transaction "id"
            _.get(transaction, 'id'),
            // Transaction "action"
            _.get(transaction, 'action'),
            // Formatted Transaction "amount"
            getAmountDisplay(
              _.get(transaction, 'amount'),
              _.get(transaction, 'currency'),
              priceConfig
            ),
            // Formatted Transaction "gateway"
            getGatewayDisplay(_.get(transaction, 'gateway')),
            // Transaction "code"
            _.get(transaction, 'code'),
            // Transaction "authCode"
            _.get(transaction, 'authCode'),
            // Formatted Transaction "status"
            getStatusDisplay(
              _.get(transaction, 'status'),
              _.get(transaction, 'action')
            )
          ];
        } catch (error) {
          _.get(console, 'error')({ method: 'loadTransactionRow', error });

          return error;
        }
      };

      const results = _.reduce(
        transactionsArray,
        (transactionRows, transaction) => [
          ...transactionRows,
          loadTransactionRow(transaction)
        ],
        []
      );

      _.get(console, 'info')({ method: 'loadTransactionRows', results });

      return results;
    };

    const transactionItems = loadTransactionRows(transactions);

    setRows(transactionItems);

    return undefined;
  }, [priceConfig, transactions]);

  if (loading) return <CircleProgress size="3em" />;

  if (_.size(transactions) === 0) return <p>No transactions to show</p>;

  return (
    <Fragment>
      <Typography variant="h3">
        Transactions: Invoice #{invoiceNumber}
      </Typography>
      <MUIDataTable options={options} data={rows} columns={columns} />
    </Fragment>
  );
};

export default isAuthenticated(TransactionTable);

import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useMutation } from '@apollo/client';

import sendInvoiceEmail from './mutations/sendInvoiceEmail';

import './list-table.scss';

const InvoicesTable = (props) => {
  const {
    invoices
  } = props;

  const [sendInvoiceEmailMutation] = useMutation(sendInvoiceEmail);

  const options = {
    elevation: 0,
    selectableRows: 'none',
    download: false,
    print: false,
    viewColumns: false,
    search: false,
    filter: true,
    filterType: 'multiselect',
    rowsPerPage: [50],
    rowsPerPageOptions: false,
    sortOrder: { name: 'Date', direction: 'asc' },
  };

  const resendInvoice = (id) => {
    sendInvoiceEmailMutation({ variables: { invoiceId: id } });
  };

  const columns = [
    {
      name: 'Date',
      options: { filter: false, sort: true }
    },
    {
      name: 'Invoice #',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'Customer ID',
      options: { filter: false, sort: true }
    },
    {
      name: 'Customer Name',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'Balance',
      options: {
        filter: false,
        sort: true,
        setCellHeaderProps: () => ({
          className: 'right-aligned'
        }),
        customBodyRender: (value) => (
          <div className="text-right">
            {`$${value}`}
          </div>
        )
      }
    },
    {
      name: '',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          value
            ? <NavLink to={`/customer/${value}`}>View</NavLink> : 'N/A'
        )
      }
    },
    {
      name: '',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          value
            ? <NavLink to='#' onClick={() => {
              if (window.confirm('Are you sure you want to resend invoice email?')) {
                resendInvoice(value);
              }
            }}>Resend Invoice</NavLink>
            : 'N/A'
        )
      }
    },
  ];

  const getRow = (invoice) => {
    const {
      id, number, balance, created, customerId, customerSnapshot
    } = invoice;
    const customer = customerSnapshot && JSON.parse(customerSnapshot);

    return [
      dayjs(created).format('MM/DD/YYYY'),
      number,
      customerId,
      customer && `${customer.firstName} ${customer.lastName}`,
      balance,
      customerId,
      id
    ];
  };
  const rows = invoices.map((invoice) => getRow(invoice));
  const sum = _.sumBy(invoices, (invoice) => invoice.balance || 0);

  return (
    <Fragment>
      <span className="total-balance">Total Balance: ${sum}</span>
      <MUIDataTable data={rows} columns={columns} options={options} />
    </Fragment>
  );
};

export default InvoicesTable;

import { gql } from '@apollo/client';

export default gql`
  query searchCustomersQuery($input: QueryCustomersInput) {
    customers: searchCustomers(input: $input) {
      id
      externalId
      firstName
      lastName
      address
      address2
      locality
      region
      postal
      country
      email
      language
      phone
    }
  }
`;

import _ from 'lodash';
import React, { Fragment, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/client';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import CreateIcon from '@material-ui/icons/Create';
import getNextBill from '../../queries/getNextBill';
import Can from '../../../../components/auth/userCanPerform';
import ISOToUTC from '../../utils/iso-to-utc';
import checkNextBillDate from '../../utils/checkNextBillDate';
import { authContext } from '../../../../contexts/authContext';
import PaymentMethods from './card-payment-methods';
import Products from './card-products';

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: '400px',
    marginTop: '15px',
    marginRight: '15px'
  },
  title: {
    fontWeight: 'bold'
  },
  divider: {
    marginTop: '10px'
  },
  cancelledText: {
    color: theme.palette.error.main,
    fontWeight: 'bold'
  },
  activeText: {
    color: theme.palette.success.main,
    fontWeight: 'bold'
  }
}));

const getDisplayStatus = (status, nextBillDisplay, end) => {
  console.log({
    message: 'getDisplayStatus - input',
    nextBillDisplay,
    status,
    end
  });

  if (status === 'active' && nextBillDisplay === 'No Information') return 'Pending Cancellation';

  return _.startCase(status);
};

const renderPrice = (frequency, currency, total) => (
    <div>
      {currency ? currency.symbol : ''}
      {total || ''} {currency ? currency.name : ''}{' '}
      {frequency || ''}
    </div>
);

const renderEditButton = (customerId, id, navigateToEdit, auth) => {
  if (!auth) {
    return null;
  }
  return (
    <Can
      groups={auth.groups}
      perform="subscription:edit"
      yes={() => (
          <Button
            size="small"
            color="primary"
            startIcon={<CreateIcon />}
            onClick={() => {
              navigateToEdit(customerId, id);
            }}
          >
            Edit
          </Button>
      )}
    />
  );
};

const SubscriptionCard = (props) => {
  const classes = useStyles();
  const { subscription, navigateToEdit, customerId } = props;
  const auth = useContext(authContext);
  const {
    id,
    start,
    end,
    status,
    products,
    total,
    paymentMethods = [],
    paymentMethodIds = [],
    frequency,
    currency,
    cancellationReason,
    cancellationNote
  } = subscription;
  const subscriptionId = id.substring(id.length - 10);
  const startDate = ISOToUTC(start);
  const endDate = end ? ISOToUTC(end) : null;
  const renderedPrice = renderPrice(frequency, currency, total);
  const editButton = renderEditButton(customerId, id, navigateToEdit, auth);
  const { loading, data: billData } = useQuery(getNextBill, {
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        subscriptionId: id
      }
    }
  });

  return (
    <Card className={classes.card} raised={true} elevation={2}>
      <CardContent>
        <Typography
          variant="subtitle1"
          className={classes.title}
          gutterBottom
          align="center"
        >
          Subscription #{subscriptionId}
        </Typography>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="subtitle2" className={classes.title}>
              Status
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="subtitle2"
              className={
                status === 'active' ? classes.activeText : classes.cancelledText
              }
            >
              {getDisplayStatus(status, checkNextBillDate(billData), endDate)}
            </Typography>
          </Grid>
          {status === 'cancelled'
            && cancellationReason
            && cancellationReason.name ? (
            <Fragment>
              <Grid item xs={6}>
                <Typography variant="subtitle2" className={classes.title}>
                  Cancellation Reason
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2">
                  {cancellationReason.name}
                </Typography>
              </Grid>
            </Fragment>
            ) : null}
          <Grid item xs={6}>
            <Typography variant="subtitle2" className={classes.title}>
              Start Date
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2">{startDate}</Typography>
          </Grid>
          {status === 'cancelled' ? (
            <Fragment>
              <Grid item xs={6}>
                <Typography variant="subtitle2" className={classes.title}>
                  End Date
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2">{endDate}</Typography>
              </Grid>
            </Fragment>
          ) : null}
          {status === 'active' ? (
            <Fragment>
              <Grid item xs={6}>
                <Typography variant="subtitle2" className={classes.title}>
                  Price
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2">{renderedPrice}</Typography>
              </Grid>
            </Fragment>
          ) : null}
          {status === 'active' ? (
            <Fragment>
              <Grid item xs={6}>
                <Typography variant="subtitle2" className={classes.title}>
                  Next Bill Date
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle2">{loading ? 'Loading...' : checkNextBillDate(billData)}</Typography>
              </Grid>
            </Fragment>
          ) : null}
          <PaymentMethods paymentMethods={paymentMethods} paymentMethodIds={paymentMethodIds} />
          {status === 'cancelled' && cancellationNote ? (
            <Grid item xs={6}>
              <Typography variant="subtitle2" className={classes.title}>
                Cancellation Notes
              </Typography>
              <Typography variant="body2">{cancellationNote}</Typography>
            </Grid>
          ) : null}
        </Grid>
        <Divider className={classes.divider} />
        <Products products={products} />
        {status === 'active' ? editButton : null}
      </CardContent>
    </Card>
  );
};

export default SubscriptionCard;

import _ from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import CreditCardDisplay from '../../../../components/credit-card/logo-and-last-four';
import ExpirationDisplay from '../../../../components/credit-card/expiration';
import getPaymentMethods from '../../queries/getPaymentMethods';
import getPaymentMethodDetails from '../../utils/get-payment-method-details-from-vault';

const useStyles = makeStyles((theme) => ({
  empty: {
    marginTop: '10px'
  },
  table: {
    maxWidth: '800px',
    marginBottom: '15px'
  },
  title: {
    marginTop: '15px',
    fontWeight: 'bold',
    fontSize: '.9em'
  },
  cell: {
    borderBottom: '0px'
  },
  label: {
    fontWeight: 'bold'
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  divider: {
    marginTop: '15px'
  }
}));

const AddExistingPaymentMethod = (props) => {
  const classes = useStyles();
  const { customerId, handleChange, subscriptionPaymentMethods } = props;
  const [combinedData, setCombinedData] = useState([]);
  const { loading, data } = useQuery(getPaymentMethods, {
    client: props.client,
    variables: { input: { externalId: customerId } }
  });

  useEffect(() => {
    if (data == null) return;

    const updatedPaymentMethods = [];
    const paymentMethods = _.get(data, 'getPaymentMethods');
    const requests = paymentMethods.map((item) => getPaymentMethodDetails(item.token));
    Promise.all(requests).then((responses) => {
      for (const response of responses) {
        updatedPaymentMethods.push(response);
      }
      const concatData = paymentMethods.map(
        (item, i) => ({ ...item, ...updatedPaymentMethods[i] })
      );

      setCombinedData(concatData);
    });
  }, [data]);

  if (loading || !data) {
    return null;
  }
  const { getPaymentMethods: paymentMethods } = data;

  // we want everything the client has except what is already assinged to this subscription
  const customerPaymentMethods = [];
  paymentMethods.forEach((item) => {
    if (!subscriptionPaymentMethods.find((subItem) => subItem.id === item.id)) {
      customerPaymentMethods.push(item);
    }
  });

  if (combinedData.length <= 1) {
    return (
      <Fragment>
        <Typography variant="subtitle2" className={classes.title}>
          Available to Add
        </Typography>
        <p className={classes.empty}>Customer has no other payment methods</p>
        <Divider className={classes.divider} />
      </Fragment>
    );
  }

  const rows = combinedData.map((item, index) => {
    const {
      id, last, brand, expiration
    } = item;
    return {
      id,
      last,
      displayCard: <CreditCardDisplay last={last} brand={brand} />,
      displayExpiration: <ExpirationDisplay expiration={expiration} />
    };
  });

  const selectNewMethod = (event) => {
    const { currentTarget } = event;
    const { value, checked } = currentTarget;
    const selectedMethod = combinedData.find((item) => item.id === value);

    handleChange(checked, selectedMethod);
  };

  return (
    <div>
      <Typography variant="subtitle2" className={classes.title}>
        Available Payment Methods
      </Typography>
      <Table className={classes.table}>
        <TableBody>
          {rows.map((row, index) => {
            const { last, displayCard, displayExpiration } = row;
            return (
              <TableRow key={`${last}-${index}`}>
                <TableCell className={classes.cell} align="left" size="small">
                  <Checkbox
                    value={row.id}
                    onChange={selectNewMethod}
                    checked={row.id === subscriptionPaymentMethods[0]}
                  />
                </TableCell>
                <TableCell
                  className={`${classes.cell} ${classes.cardContainer}`}
                  align="left"
                  size="small"
                >
                  {displayCard}
                </TableCell>
                <TableCell className={classes.cell} align="left" size="small">
                  {displayExpiration}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Divider className={classes.divider} />
    </div>
  );
};

export default AddExistingPaymentMethod;
